<template>
  <div>
     <paper-report-table :papers="papers" />
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api.js";
import PaperReportTable from "../Print/components/PaperReportTable.vue";

export default {
  data: () => ({
    papers: []
  }),
  components: {
    PaperReportTable
  },
  mounted() {
    this.getPapers();
  },
  methods: {
    async getPapers() {
      await this.$store.dispatch("loading", true);
      try {
        this.boxes = await Trainplanet.getPapers();
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message);
      }
      await this.$store.dispatch("loading", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
